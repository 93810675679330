import Vue from 'vue'
import {
  ToastPlugin,
  ModalPlugin
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

import vBlur from 'v-blur'

Vue.use(vBlur)


// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

//import Firebase
import {
  firestorePlugin
} from 'vuefire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

//Firebase config
const devConfig = {
  apiKey: "AIzaSyDwedKrlWWlFfRb7ArPT-PDx9vNCtz8Gk8",
  authDomain: "videoquest-f9551.firebaseapp.com",
  projectId: "videoquest-f9551",
  storageBucket: "videoquest-f9551.appspot.com",
  messagingSenderId: "127408812116",
  appId: "1:127408812116:web:6a453ea49b846a17973dc1",
  measurementId: "G-WNCJ907BFG"
};

const prodConfig = {
  apiKey: "AIzaSyA_2J0Yn_cjsNJqHexFa6VXdUaZ9gUHTuA",
  authDomain: "videoq-9f74f.firebaseapp.com",
  projectId: "videoq-9f74f",
  storageBucket: "videoq-9f74f.appspot.com",
  messagingSenderId: "150080115094",
  appId: "1:150080115094:web:e9b60d98d72a6bf2024db7"
};

const whiteConfig = {
  apiKey: "AIzaSyC4q0VXLWX9FBviPZRcQZgvY6p9V53o09E",
  authDomain: "videoq-white-320a7.firebaseapp.com",
  projectId: "videoq-white-320a7",
  storageBucket: "videoq-white-320a7.appspot.com",
  messagingSenderId: "967347198732",
  appId: "1:967347198732:web:85266184c836682a454729",
  measurementId: "G-2H9010J65H"
}
//Firebase config

export const devCloudinaryConfig = {
  preset: "rhsiinlg",
  cloudName: "dter7pjsd",
  apiKey: "511144294813784",
  apiSecret: "s9mI3QW5ggCHZE2X20JazB_o0-A",
};

export const prodCloudinaryConfig = {
  preset: 'mixplovs',
  cloudName: 'dsc6tqvgh',
  apiKey: '195798294954868',
  apiSecret: 'mw-Y2Kb8CM7edkiBNf6Ay2AEmv4',
};

export const whiteCloudinaryConfig = {
  preset: 'mixplovs',
  cloudName: 'dsc6tqvgh',
  apiKey: '195798294954868',
  apiSecret: 'mw-Y2Kb8CM7edkiBNf6Ay2AEmv4',
}


const devCloudPaymentApi = "test_api_00000000000000000000001";
const prodCloudPaymentApi = "pk_2e47c6c7016794baab23783f2acf8"

let config = 'dev';
if (!process.env.VUE_APP_ENV || process.env.VUE_APP_ENV === 'dev') {
  config = 'dev'
} else if (process.env.VUE_APP_ENV === 'prod') {
  config = 'prod'
}
else if (process.env.VUE_APP_ENV === 'white') {
  config = 'white'
}

export const cloudPaymentApi = (config === 'dev' ? devCloudPaymentApi : prodCloudPaymentApi)
export const fb = firebase.initializeApp(config === 'dev' ? devConfig : config === 'prod' ? prodConfig : whiteConfig);
export const db = fb
  .firestore()
Vue.use(firestorePlugin)
export const cloudinaryConf = (config === 'dev' ? devCloudinaryConfig :  config === 'prod' ? prodCloudinaryConfig : whiteCloudinaryConfig)

export const emailIntegrationConf = 'https://hook.integromat.com/vkilzpmssui85e5e6v5ah14rkdwjajq6'

import VueMeta from 'vue-meta'

Vue.use(VueMeta)

import VideoBackground from 'vue-responsive-video-background-player';
Vue.component('video-background', VideoBackground);

// Initialize Firebase
Vue.config.productionTip = false

import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')