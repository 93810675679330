import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import uiElements from './routes/ui-elements/index'
import projects from './routes/projects'
import pages from './routes/pages'
import leadForms from './routes/lead-forms'
import integrations from './routes/integrations'
import admin from './routes/admin'
import firebase from 'firebase/compat/app';
import { fb, db } from "@/main";

import store from "@/store";
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'projects' } },
    ...uiElements,
    ...projects,
    ...pages,
    ...leadForms,
    ...integrations,
    ...admin,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const redirectToVerification = route => {
  if (route.name != 'verification')
    return { name: 'verification', replace: true };
}

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const isMain = process.env.VUE_APP_ENV !== 'white'
  console.log(isMain)
  firebase.auth().onAuthStateChanged(function(user) {
    if (user && user.email && !user.emailVerified && user.metadata.createdAt >= 1641825897968 && to.name !== 'verification' && isMain) {
      return next({name: 'verification'})
    }
    if ((user && user.emailVerified) || (user && !isMain)) {
      db.collection('users').doc(user.uid).update({
        isConfirmed: true,
      })
    }
  });
  if (!canNavigate(to)) {
    if (to.name === 'body-quest')
    {
      return next()
    }
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }
  if(to.meta.resource == 'Admin') {
    const userData = getUserData()
    if(userData.role !== 'admin') {
      return next({ name: 'misc-not-authorized' })
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  if (to.meta.layout === 'full') {
    router.app.$store.state.appConfig.layout.type = 'full'
  }
  else router.app.$store.state.appConfig.layout.type = 'vertical'
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
