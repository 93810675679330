import { db, fb } from '@/main';
import firebase from 'firebase/compat/app';

export default {
  namespaced: true,
  state: {
    showsList: undefined,
    answersList: undefined,
    interactionsList: [],
    statList: {
      shows: [],
      answers: [],
      interactions: [],
    }
  },
  getters: {
    getShowsList: (state) => {
      return state.showsList;
    },
    getAnswersList: (state) => {
      return state.answersList
    },
    getInteractionsList: (state) => {
      return state.interactionsList
    }
  },
  mutations: {
    SET_SHOWS_LIST: (state, payload) => {
      state.statList.shows = payload
      state.showsList = payload
    },
    SET_ANSWERS_LIST: (state, payload) => {
      state.statList.answers = payload
      state.answersList = payload
    },
    SET_INTERACTIONS_LIST: (state, payload) => {
      state.statList.interactions = payload
      state.interactionsList = payload
    },
    FILTER_STAT: (state, payload) => {
      state.interactionsList = state.statList.interactions.filter((item) => {
        const date = new Date(item.timeAt.seconds * 1000).toLocaleDateString(
          "ru-RU"
        )
        if (payload.type && payload.dateEnd && payload.dateStart) 
          return item.projectId === payload.type && date <= payload.dateEnd && date > payload.dateStart
        else if (payload.type && !payload.dateEnd)
            return item.projectId === payload.type
        else if (payload.dateEnd && !payload.type)
          return date <= payload.dateEnd && date > payload.dateStart
        return true;
      })
      state.answersList = state.statList.answers.filter((item) => {
        const date = new Date(item.timeAt.seconds * 1000).toLocaleDateString(
          "ru-RU"
        )
        if (payload.type && payload.dateEnd && payload.dateStart)
          return item.projectId === payload.type && date <= payload.dateEnd && date > payload.dateStart
        else if (payload.type && !payload.dateEnd)
          return item.projectId === payload.type
        else if (payload.dateEnd && !payload.type)
          return date <= payload.dateEnd && date > payload.dateStart
        return true;
      })
      state.showsList = state.statList.shows.filter((item) => {
        const date = new Date(item.timeAt.seconds * 1000).toLocaleDateString(
          "ru-RU"
        )
        if (payload.type && payload.dateEnd && payload.dateStart)
          return item.projectId === payload.type && date <= payload.dateEnd && date > payload.dateStart
        else if (payload.type && !payload.dateEnd)
          return item.projectId === payload.type
        else if (payload.dateEnd && !payload.type)
          return date <= payload.dateEnd && date > payload.dateStart
        return true;
      })
    },
    CLEAR_STATS: (state) => {
      state.showsList = undefined;
      state.answersList = undefined;
      state.interactions = undefined;
    }
  },
  actions: {
    requestShowsList: ({commit}, payload) => {
      db.collection('users').doc(localStorage.getItem('userId')).get().then(user => {
        let request = db.collection('shows');
        if (user.data().isAdmin) {
          request = db.collection('shows')
        }
        else request = db.collection('shows').where('userId', '==', localStorage.getItem('userId'))
        if (payload) {
          if (payload.projectId) request = request.where('projectId', '==', payload.projectId)
          if (payload.dateStart) request = request.where('timeAt', '>=', payload.dateStart)
          if (payload.dateEnd) request = request.where('timeAt', '<=', payload.dateEnd)
        }
        request.get().then(querysnapshot => {
          const res = [];
          querysnapshot.forEach((show) => {
          res.push(show.data())
        })
        commit('SET_SHOWS_LIST', res);
        });
      })
    },
    requestAnswersList: ({commit}, payload) => {
      db.collection('users').doc(localStorage.getItem('userId')).get().then(user => {
        let request = db.collection('answers');
        if (user.data().isAdmin) {
          request = db.collection('answers')
        }
        else request = db.collection('answers').where('userId', '==', localStorage.getItem('userId'))
        if (payload) {
          if (payload.projectId) request = request.where('projectId', '==', payload.projectId)
          if (payload.dateStart) request = request.where('timeAt', '>=', payload.dateStart)
          if (payload.dateEnd) request = request.where('timeAt', '<=', payload.dateEnd)
        }
        request.get().then(querysnapshot => {
          const res = [];
          querysnapshot.forEach((answer) => {
          res.push(answer.data())
        })
        commit('SET_ANSWERS_LIST', res);
        });
      })
    },
    requestInteractionsList: ({commit}, payload) => {
      db.collection('users').doc(localStorage.getItem('userId')).get().then(user => {
        let request = db.collection('interactions');
        if (user.data().isAdmin) {
          request = db.collection('interactions')
        }
        else request = db.collection('interactions').where('userId', '==', localStorage.getItem('userId'))
        if (payload) {
          if (payload.projectId) request = request.where('projectId', '==', payload.projectId)
          if (payload.dateStart) request = request.where('timeAt', '>=', payload.dateStart)
          if (payload.dateEnd) request = request.where('timeAt', '<=', payload.dateEnd)
        }
        request.get().then(querysnapshot => {
          const res = [];
          querysnapshot.forEach((answer) => {
          res.push(answer.data())
        })
        commit('SET_INTERACTIONS_LIST', res);
        });
      })
    },

    filterStat: ({commit}, payload) => {
      commit('FILTER_STAT', payload)
    },
    clearStatList: ({commit}) => {
      commit('CLEAR_STATS')
    }
  }
}