import { db } from "@/main";
import firebase from "firebase/compat/app";
import axios from "axios";
import { cloudinaryConf } from "@/main";
import * as _ from "lodash"

export default {
  namespaced: true,
  state: {
    users: {
      data: [],
      answer: {},
      status: false,
    },
    projects: {
      data: [],
      answer: {},
      status: false,
    },
    answers: {
      data: [],
      answer: {},
      status: false,
    },
    leadForms: {
      data: [],
      answer: {},
      status: false,
    },
    integrations: {
      data: [],
      answer: {},
      status: false,
    },
    shows: {
      data: [],
      answer: {},
      status: false,
    },
    interactions: {
      data: [],
      answer: {},
      status: false,
    },

  },
  getters: {
    getUsers: (state) => {
      return state.users;
    },
    getProjects: (state) => {
      return state.projects;
    },
    getAnswers: (state) => {
      return state.answers;
    },
    getLeadForms: (state) => {
      return state.leadForms;
    },
    getIntegrations: (state) => {
      return state.integrations
    },
    getInteractions: (state) => {
      return state.interactions
    },
    getShows: (state) => {
      return state.shows
    }
  },
  mutations: {
    SET_USERS: (state, payload) => {
      state.users = {
        data: payload.data,
        answer: payload.answer,
        status: true
      };
    },
    SET_PROJECTS: (state, payload) => {
      state.projects = {
        data: payload.data,
        answer: payload.answer,
        status: true
      };
    },
    SET_ANSWERS: (state, payload) => {
      state.answers = {
        data: payload.data,
        answer: payload.answer,
        status: true
      };
    },
    SET_LEADFORMS: (state, payload) => {
      state.leadForms = {
        data: payload.data,
        answer: payload.answer,
        status: true
      }
    },
    EDIT_FORM: (state, payload) => {
      const index = state.leadForms.data.findIndex(form => form.id === payload.id)
      state.leadForms.data[index].name = payload.name;
      state.leadForms.data[index].questions = payload.questions;
      state.leadForms.data[index].title = payload.title;
      state.leadForms.data[index].sucсessText = payload.sucсessText;
      state.leadForms.answer[payload.id] = {...state.leadForms.answer[payload.id], ...payload}
    },
    SET_INTEGRATIONS: (state, payload) => {
      state.integrations = {
        data: payload.data,
        answer: payload.answer,
        status: true
      }
    },
    SET_SHOWS: (state, payload) => {
      state.shows = {
        data: payload.data,
        answer: payload.answer,
        status: true
      }
    },
    SET_INTERACTIONS: (state, payload) => {
      state.interactions = {
        data: payload.data,
        answer: payload.answer,
        status: true
      }
    },
  },
  actions: {
    requestUsers: ({commit}) => {
      db.collection('users').get().then(querysnapshot  => {
        const resultData = [];
        const result = {};
        querysnapshot.forEach(user => {
          resultData.push(user.data());
          result[user.id] = user.data();
        });
        commit("SET_USERS", { data: resultData, answer: result });
      })
    },
    setUserStatus: ({commit}, payload) => {
      db.collection('users').doc(payload.id).update({status: payload.status})
    },
    requestProjects: ({commit}) => {
      db.collection('projects').get().then(querysnapshot => {
        const resultData = [];
        const result = {};
        querysnapshot.forEach(project => {
          resultData.push(project.data());
          result[project.id] = project.data();
        });
        commit("SET_PROJECTS", { data: resultData, answer: result })
      })
    },
    requestAnswers: ({commit}) => {
      db.collection('answers').get().then(querysnapshot => {
        const resultData = [];
        const result = {};
        querysnapshot.forEach(answer => {
          resultData.push(answer.data());
          result[answer.id] = answer.data();
        });
        commit("SET_ANSWERS", { data: resultData, answer: result });
      })
    },
    requestLeadForms: ({commit}) => {
      db.collection('leadForms').get().then(querysnapshot => {
        const resultData = [];
        const result = {};
        querysnapshot.forEach(leadForm => {
          resultData.push(leadForm.data());
          result[leadForm.id] = leadForm.data();
        })
        commit("SET_LEADFORMS", { data: resultData, answer: result });
      })
    },
    editForm: ({commit}, payload) => {
      db.collection('leadForms').doc(payload.id).update({ 
        name: payload.name, 
        questions: payload.questions, 
        sucсessText: payload.sucсessText, 
        title: payload.title})
        .then(() => {
          commit("EDIT_FORM", payload)
        })
    },
    requestIntegrations: ({commit}) => {
      db.collection('integrations').get().then(querysnapshot => {
        const resultData = [];
        const result = {};
        querysnapshot.forEach(integration => {
          resultData.push(integration.data());
          result[integration.id] = integration.data();
        })
        commit("SET_INTEGRATIONS", { data: resultData, answer: result });
      })
    },
    requestShows: ({commit}) => {
      db.collection('shows').get().then(querysnapshot => {
        const resultData = [];
        const result = {};
        querysnapshot.forEach(show => {
          resultData.push(show.data());
          result[show.id] = show.data();
        })
        commit("SET_SHOWS", { data: resultData, answer: result });
      })
    },
    requestInteractions: ({commit}) => {
      db.collection('interactions').get().then(querysnapshot => {
        const resultData = [];
        const result = {};
        querysnapshot.forEach(interaction => {
          resultData.push(interaction.data());
          result[interaction.id] = interaction.data();
        })
        commit("SET_INTERACTIONS", { data: resultData, answer: result });
      })
    },
    updateTariff: ({commit}, payload) => {
      db.collection('users').doc(payload.id).update({
        tariff: payload.tariff
      })
    },
    updateTariffTill: ({commit}, payload) => {
      return new Promise((resolve, reject) => {
        db.collection('users').doc(payload.user.userId).update({
          tariffTill: firebase.firestore.Timestamp.fromDate(
            payload.changedDate
          ),
        }).then(() => {
          resolve()
        })
      })
    },
    removeUser: ({commit}, payload) => {
      return new Promise((resolve, reject) => {
        db.collection('users').doc(payload).delete().then(async () => {

          // Delete folder from cloudinary
          let cloudinaryDeleteURL = `https://api.cloudinary.com/v1_1/${cloudinaryConf.cloudName}/folders/${payload}`;
          let requestObj = {
            url: cloudinaryDeleteURL,
            method: "DELETE",
          };
          axios(requestObj)
          // Delete frolder ends
  
          // Delete Answers
          const answers = await db
            .collection('answers')
            .where('userId', '==', payload)
            .get();
          const answersBatches = _.chunk(answers.docs, 500).map(bDocs => {
            const batch = db.batch()
            bDocs.forEach(doc => {
                batch.delete(doc.ref)
            })
            return batch.commit()
          })
          await Promise.all(answersBatches)
          // Delete Answers Ends
  
          // Delete integrations
          const integrations = await db
            .collection('integrations')
            .where('userId', '==', payload)
            .get();
          const integrationsBatches = _.chunk(integrations.docs, 500).map(bDocs => {
            const batch = db.batch()
            bDocs.forEach(doc => {
                batch.delete(doc.ref)
            })
            return batch.commit()
          })
          await Promise.all(integrationsBatches)
          // Delete integrations Ends
  
          // Delete interactions
          const interactions = await db
            .collection('interactions')
            .where('userId', '==', payload)
            .get();
          const interactionsBatches = _.chunk(interactions.docs, 500).map(bDocs => {
            const batch = db.batch()
            bDocs.forEach(doc => {
                batch.delete(doc.ref)
            })
            return batch.commit()
          })
          await Promise.all(interactionsBatches)
          // Delete interactions Ends
          
          // Delete leadForms
          const leadForms = await db
            .collection('leadForms')
            .where('userId', '==', payload)
            .get();
          const leadFormsbatches = _.chunk(leadForms.docs, 500).map(bDocs => {
            const batch = db.batch()
            bDocs.forEach(doc => {
                batch.delete(doc.ref)
            })
            return batch.commit()
          })
          await Promise.all(leadFormsbatches)
          // Delete leadForms Ends
  
          // Delete payments
          const payments = await db
            .collection('payments')
            .where('userId', '==', payload)
            .get();
          const paymentsBatches = _.chunk(payments.docs, 500).map(bDocs => {
            const batch = db.batch()
            bDocs.forEach(doc => {
                batch.delete(doc.ref)
            })
            return batch.commit()
          })
          await Promise.all(paymentsBatches)
          // Delete payments ends
  
          // Delete shows
          const shows = await db
            .collection('shows')
            .where('userId', '==', payload)
            .get();
          const showsBatches = _.chunk(shows.docs, 500).map(bDocs => {
            const batch = db.batch()
            bDocs.forEach(doc => {
                batch.delete(doc.ref)
            })
            return batch.commit()
          })
          await Promise.all(showsBatches)
          // Delete shows ends
  
          db.collection('projects').where('userId', '==', payload).get().then(snap => {
            snap.forEach(doc => {
              doc.data().sources.forEach(sourse => {
                db.collection('sources').doc(sourse).delete()
              });
              doc.ref.delete();
            });
            resolve()
          })
        })
      })
    },
    requestUsersLeadForms: ({commit}, payload) => {
      db.collection('leadForms').where("userId", '==', payload.userId).get().then(querysnapshot => {
        const resultData = [];
        const result = {};
        querysnapshot.forEach(leadForm => {
          resultData.push(leadForm.data());
          result[leadForm.id] = leadForm.data();
        })
        commit("SET_LEADFORMS", { data: resultData, answer: result });
      })
    }
  }
}