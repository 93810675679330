import { db, fb } from '@/main';
import firebase from 'firebase/compat/app';

export default {
  namespaced: true,
  state: {
    projects: [],
    currentProject: null,
    currentSource: null,
    sourcesList: [],
    controlsList: [],
    socialsList: {}
  },
  getters: {
    getProjects: (state) => {
      return state.projects;
    },
    getProjectData: (state) => {
      return state.currentProject
    },
    getCurrentSource: (state) => {
      return state.currentSource
    },
    getControls: (state) => {
      return state.controlsList
    },
    getSourcesList: (state) => {
      return state.sourcesList;
    },
    getSocialsList: (state) => {
      return state.socialsList;
    }
  },
  mutations: {
    SET_PROJECTS_LIST: (state, payload) => {
      state.projects = [...payload];
    },
    DELETE_PROJECT: (state, payload) => {
      const filteredProjects =  state.projects.filter(project => project.id !== payload.id);
      state.projects = filteredProjects
    },
    ADD_PROJECT: (state, payload) => {
      state.projects.push(payload)
    },
    SET_CURRENT_PROJECT: (state, payload) => {
      state.currentProject = payload;
    },
    SET_CURRENT_SOURCE: (state, payload) => {
      state.currentSource = payload
    },
    SET_SOURCES_LIST: (state, payload) => {
      if (payload) {
        state.sourcesList = payload;
        state.controlsList = payload[0].controls;
        state.currentSource = payload[0];
        state.socialsList = payload[0].socials
      }
      else {
        state.sourcesList = [];
        state.currentSource = null;
        state.controlsList = [];
        state.socialsList = {}
      }
    },
    SET_NEXT_SOURCE: (state, payload) => {
      const sources = state.sourcesList;
      const filteredProject = sources.find(source => {
        return source.id == payload;
      });
      state.currentSource = filteredProject;
      state.controlsList = null;
      state.controlsList = filteredProject.controls;
      state.socialsList = filteredProject.socials
    },
    SET_CONTROLS: (state, payload) => {
      state.controlsList = payload
    },
    SET_SOCIALS: (state, payload) => {
      if (payload)
        state.socialsList = payload
      else state.socialsList = {}
    },
    ADD_CONTROL: (state, payload) => {
      state.controlsList.push(payload)
    },
    REMOVE_CONTROL: (state, payload) => {
      state.controlsList = state.controlsList.filter(control => control.id !== payload.button.id)  
    },
    ADD_SOURCE: (state, payload) => {
      state.sourcesList.push(payload);
      state.currentProject.sources.push(payload.id);
    },
    UPDATE_SOURCE_TIME: (state, payload) => {
      const clone = {...state.currentSource}
      clone.url = payload.url;
      clone.startAt = payload.startAt;
      clone.endAt = payload.endAt;
      state.currentSource = clone;
    },
    LINK_INTEGRATIONS: (state, payload) => {
      if (payload.status) {
        state.currentProject.integrations.push(payload.integration)
      } else {
        state.currentProject.integrations = state.currentProject.integrations.filter(integration => integration !== payload.integration)
      }
    }
  }, 
  actions: {
    setCurrentSource: ({commit}, payload) => {
      if (payload)
      {
        commit("SET_CONTROLS", payload.controls);
        commit("SET_SOCIALS", payload.socials)
      }
      else 
        {
          commit("SET_CONTROLS", []);
          commit("SET_SOCIALS", {})
        } 
      commit("SET_CURRENT_SOURCE", payload);
    },
    createSource: ({commit}, payload) => {
      return new Promise((resolve, reject) => {
        db.collection("sources").add({
          title: payload.title,
          controls: [],
          interactionType: 'buttons',
          socials: {}
        }).then((docRef) => {
          db.collection("sources")
            .doc(docRef.id)
            .update({
              id: docRef.id,
            });
          db.collection("projects").doc(payload.projectId).update({
            sources: firebase.firestore.FieldValue.arrayUnion(docRef.id),
          }).then(() => {
            commit("ADD_SOURCE", {
                id: docRef.id,
                title: payload.title,
                controls: [],
            })
            resolve(docRef.id)
          })
        }).catch(() => reject())
      });
    },
    requestProjects: ({commit}) => {
      return new Promise((resolve, reject) => {
        db.collection('users').doc(localStorage.getItem('userId')).get().then(user => {
          let request = db.collection("projects");
          if (user.data().isAdmin) {
            request = db.collection("projects")
          }
          else request = db.collection("projects").where("userId", "==", localStorage.getItem('userId'))
          request.get().then(querysnapshot => {
            const results = []
            querysnapshot.docs.forEach(doc => {
              results.push({id: doc.id, ...doc.data()})
            })
            commit("SET_PROJECTS_LIST", results)
            resolve(results)
          });
        })
      })
    },
    deleteProject: ({commit}, payload) => {
      db.collection("projects").doc(payload.id).delete().then(() => {
        commit("DELETE_PROJECT", payload);
      })
    },
    createProject: ({commit}, payload) => {
      const project = {
        name: payload,
        shows: 0,
        clicks: 0,
        interactions: 0,
        sources: [],
        integrations: [],
        userId: localStorage.getItem('userId'),
      }
      return new Promise((resolve, reject) => {
        db.collection("projects").add(project).then(docRef => {
          const createdProject = {
            id: docRef.id,
            ...project,
          }
          resolve(createdProject)
          commit("ADD_PROJECT", createdProject)
        }).catch(e => {
          reject()
        })
      })
    },
    requestProjectData: ({commit}, payload) => {
      return new Promise((resolve, reject) => {
        db.collection("projects").doc(payload).get().then(doc => {
          const projectsData = doc.data();
          commit("SET_CURRENT_PROJECT", projectsData);
          if (projectsData.sources.length > 0)
            db.collection("sources").where("id", 'in', projectsData.sources).get().then(ans => {
              const results = []
              ans.forEach(doc => {
                results.push(doc.data());
              });
              const sortedList = [];
              projectsData.sources.forEach(source => {
                const sortedItem = results.find(item => {
                  return item.id === source
                });
                sortedList.push(sortedItem)
              })
              commit('SET_SOURCES_LIST', sortedList);
              resolve(projectsData)
            })
          else commit('SET_SOURCES_LIST', []);
        }).catch(e => {
          reject(e)
        })
      })
    },
    requestNextSource: ({commit}, payload) => {
      commit("SET_NEXT_SOURCE", payload)
    },
    setInteractionType: ({commit}, payload) => {
      return new Promise((resolve, reject) => {
        db.collection('sources').doc(payload.id).update({interactionType: payload.type}).then(() => {
          resolve();
        })
      })
    },
    setControls: ({commit}, payload) => {
      const newValue = payload.newValue.map(item => {
        let o = Object.fromEntries(Object.entries(item).filter(([_, v]) => v != null));
        return o;
      });
      return new Promise((resolve, reject) => {
        db.collection('sources').doc(payload.sourceId).update({controls: newValue}).then(() => {
          resolve();
        }).catch(e => reject(e))
        commit("SET_CONTROLS", newValue);
      })
    },
    setSocials: ({commit}, payload) => {
      const newValue = payload.newValue;
      return new Promise((resolve, reject) => {
        db.collection('sources').doc(payload.sourceId).update({socials: newValue}).then(() => {
          resolve();
        }).catch(e => reject(e))
        commit("SET_SOCIALS", newValue);
      })
    },
    addControl: ({commit}, payload) => {
      let r = (Math.random() + 1).toString(36).substring(2);
      const controlLayout = {
        type: "button",
        style: "",
        background: "#7367f0",
        text: "Новая кнопка",
        action: "nextFrame",
        id: r,
      }
      
      payload.currentControls.push(controlLayout)
      return new Promise((resolve, reject) => {
        db.collection("sources").doc(payload.sourceId).update({
          controls: payload.currentControls
        }).then(() => {
          resolve(controlLayout)
        }).catch(() => reject())
      })
    },
    removeControl: ({commit}, payload) => {
      const filteredControls = payload.source.controls.filter(control => control.id !== payload.button.id)
      db.collection('sources').doc(payload.source.id).update({controls: filteredControls}).then(() => {
        commit("REMOVE_CONTROL", payload);
      })
    },
    updateVideoRange: ({commit}, payload) => {
      const lowQuality = payload.url.replace(
        "upload/",
        `upload/q_50,c_scale,h_640,w_360,so_${payload.startAt},eo_${payload.endAt}/`
      );
      const normalQuality = payload.url.replace(
        "upload/",
        `upload/q_70,c_scale,h_854,w_480,so_${payload.startAt},eo_${payload.endAt}/`
      );
      const newSource = {
        url_low: lowQuality,
        url: normalQuality,
        startAt: payload.startAt,
        endAt: payload.endAt,
      };
      db.collection('sources').doc(payload.id).update(newSource).then(() => {
        commit('UPDATE_SOURCE_TIME', newSource)
      })
    },
    renameProject: ({commit}, payload) => {
      db.collection('projects').doc(payload.id).update({name: payload.name})
    },
    renameSource: ({commit}, payload) => {
      db.collection('sources').doc(payload.id).update({title: payload.title})
    },
    removeSource: ({commit}, payload) => {
      const projectSources = payload.project.sources.filter(source => source !== payload.source.id);
      return new Promise((resolve, reject) => {
        db.collection('projects').doc(payload.project.id).update({sources: projectSources}).then(() => {
          db.collection('sources').doc(payload.source.id).delete().then(() => {
            resolve();
          })
        })
        .catch((e) => {
          reject(e)
        })
      })
    },
    linkIntegrations: ({commit}, payload) => {
      commit("LINK_INTEGRATIONS", payload)
    }
  }
}