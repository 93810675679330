import { db, fb } from '@/main';
import firebase from 'firebase/compat/app';

export default {
  namespaced: true,
  state: {
    userData: {},
    tariff: null,
    tariffTill: null,
    tariffAt: null,
    tariffLeft: null,
    isDisabled: false,
    payments: {
      data: [],
      answer: {},
      status: false,
    },
  },
  getters: {
    getUserData: (state) => {
      return state.userData;
    },
    getTariff: (state) => {
      return state.tariff;
    },
    getTariffTill: (state) => {
      return state.tariffTill;
    },
    getTariffAt: (state) => {
      return state.tariffAt;
    },
    getTariffLeft: (state) => {
      const currentTimestamp = Date.now();
      if (state.tariffTill) {
        const tillTimestamp  = new Date(state.tariffTill.seconds*1000)
        let difference = Math.floor((tillTimestamp - currentTimestamp) / (1000 * 3600 * 24))
        if (difference < 0) difference = 0
      return difference;
      }
      else return null;
    },
    getIsDisabled: (state) => {
      return state.isDisabled;
    },
    getPayments: (state) => {
      return state.payments
    }
  },
  mutations: {
    SET_USER_DATA: (state, payload) => {
      state.userData = payload;
      state.tariff = payload.tariff ? payload.tariff : 'Free';
      state.tariffTill = payload.tariffTill ? payload.tariffTill : firebase.firestore.FieldValue.serverTimestamp();
      state.tariffAt = payload.tariffAt ? payload.tariffAt : firebase.firestore.FieldValue.serverTimestamp();
      
      // check if user disabled
      const currentTimestamp = Date.now();
      const tillTimestamp  = new Date(payload.tariffTill.seconds*1000)
      let difference = Math.floor((tillTimestamp - currentTimestamp) / (1000 * 3600 * 24))
      const isDisabled = process.env.VUE_APP_ENV !== 'white' && (!payload.status || difference < 0 )
      state.isDisabled = isDisabled
    },
    SET_PAYMENTS: (state, payload) => {
      state.payments = {
        data: payload.data,
        answer: payload.answer,
        status: true
      };
    }
  },
  actions: {
    requestUserData: ({commit}) => {
      const userId = JSON.parse(localStorage.getItem("userData")).user.uid;
      db.collection("users")
        .doc(userId)
        .get()
        .then((doc) => {
          commit('SET_USER_DATA', doc.data())
        });
    },
    updatePayment: ({commit}, payload) => {
      return new Promise((resolve, reject) => {
        const userId = JSON.parse(localStorage.getItem("userData")).user.uid;
        db.collection("payments").add({
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          tariff: payload.tariff,
          duration: payload.status,
          userId,
        }).then(() => {
          let expiresIn = 2592000;
          if(payload.status === 'annualy') {
            expiresIn = 15552000
          }
          const expireTimestamp = new Date();
          expireTimestamp.setDate(expireTimestamp.getDate() + 31);
          db.collection("users").doc(userId).update({
            tariff: payload.tariff,
            tariffAt: firebase.firestore.FieldValue.serverTimestamp(),
            tariffTill: firebase.firestore.Timestamp.fromDate(
              expireTimestamp
            ),
          }).then(() => resolve())
        })
      })
    },
    requestPayments: ({commit}, payload) => {
      db.collection("payments").where("userId", "==", localStorage.getItem('userId')).get().then(querysnapshot => {
        const resultData = [];
        const result = {};
        querysnapshot.forEach(payment => {
          resultData.push(payment.data());
          result[payment.id] = payment.data();
        })
        commit("SET_PAYMENTS", { data: resultData, answer: result });
      })
    }
  },
}