export default [
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/projects/overview/Overview.vue'),
    meta: {
      pageTitle: 'Проекты',
      breadcrumb: [
        {
          text: 'Projects',
          active: true,
        },
      ],
    }
  },
  {
    path: '/projects/:id',
    name: 'project-edit',
    component: () => import('@/views/projects/edit-project/Project.vue'),
  },
  {
    path: '/vq/:id',
    name: 'videoquest',
    component: () => import('@/views/projects/widget/Widget.vue'),
    meta: {
      layout: 'full',
      resource: 'Quest',
    }
  },
  {
    path: '/direct/:id',
    name: 'direct-quest',
    component: () => import('@/views/projects/direct/Direct.vue'),
    meta: {
      layout: 'full',
      resource: 'Quest'
    }
  },
  {
    path: '/body/:id',
    name: 'body-quest',
    component: () => import('@/views/projects/body/Body.vue'),
    meta: {
      layout: 'full',
      resourse: 'Quest',
    }
  },
  {
    path: '/statistics',
    name: 'project-statistics',
    component: () => import('@/views/statistics/Statistics.vue'),
    meta: {
      pageTitle: 'Статистика'
    }
  },
  {
    path: '/preview/:id&:name',
    name: 'project-preview',
    component: () => import('@/views/projects/preview/Preview.vue'),
  }
]