import { db, fb } from '@/main';
import firebase from 'firebase/compat/app';

export default {
  namespaced: true,
  state: {
    formsList: [],
    currentForm: null,
    formAnswers: [],
    answersObj: {res: [], isDisabled: false, status: false},
  },

  getters: {
    getFormsList(state) {
      return state.formsList
    },
    getCurrentForm(state) {
      return state.currentForm;
    },
    getAnswersObj(state) {
      return state.answersObj;
    }
  },

  mutations: {
    SET_FORMS_LIST: (state, payload) => {
      state.formsList = payload
    },
    ADD_FORM: (state, payload) => {
      state.formsList.push(payload);
    },
    DELETE_FORM: (state, payload) => {
      const filteredForms =  state.formsList.filter(form => form.id !== payload.id);
      state.formsList = filteredForms;
    },
    EDIT_FORM: (state, payload) => {
      const index = state.formsList.findIndex(form => form.id === payload.id)
      state.formsList[index].name = payload.name;
      state.formsList[index].questions = payload.questions;
      state.formsList[index].title = payload.title;
      state.formsList[index].sucсessText = payload.sucсessText;
    },
    SET_CURRENT_FORM: (state, payload) => {
      const currentForm = payload;
      currentForm.questions = payload.questions.filter(form => form.enabled)
      state.currentForm = payload;
    },
    SET_ANSWERS_OBJ: (state, payload) => {
      state.answersObj = payload;
    },
    ADD_ANSWERS_OBJ: (state, payload) => {
      state.answersObj.res.push(...payload.res)
      state.answersObj = {
        res: state.answersObj.res, 
        isDisabled: payload.isDisabled, 
        lastVisible: payload.lastVisible, 
        isEnded: payload.isEnded, 
        request: state.answersObj.request
      }
    }
  },  

  actions: {
    requestForms: ({commit}, payload) =>  {
      db.collection('users').doc(localStorage.getItem('userId')).get().then(user => {
        let request = db.collection('leadForms');
        if (user.data().isAdmin) {
          request = db.collection('leadForms')
        }
        else request = db.collection('leadForms').where('userId', '==', localStorage.getItem('userId'))
        request.get().then(querysnapshot => {
          const result = [];
          querysnapshot.forEach(doc => {
            result.push(doc.data());
          })
          commit('SET_FORMS_LIST', result)
        });
      })
    },
    addForms: ({commit}, payload) => {
      let r = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
      const form = {
        name: payload.name,
        title: payload.title,
        sucсessText: payload.sucсessText,
        quests: [],
        questions: payload.questions,
        answers: 0,
        displayId: r,
        userId: localStorage.getItem('userId')
      }
      return new Promise((resolve, reject) => {
        db.collection('leadForms').add(form).then(docRef => {
          const createdForm = {
            ...form,
            id: docRef.id,
          }
          commit('ADD_FORM', createdForm);
          db.collection('leadForms').doc(docRef.id).update({id: docRef.id}).then(() => {
            resolve(createdForm)
          }).catch(() => reject());
        })
      })
    },
    deleteForm: ({commit}, payload) => {
      db.collection('leadForms').doc(payload.id).delete().then(() => {
        commit("DELETE_FORM", payload);
      })
    },
    editForm: ({commit}, payload) => {
      db.collection('leadForms').doc(payload.id).update({ 
        name: payload.name, 
        questions: payload.questions, 
        sucсessText: payload.sucсessText, 
        title: payload.title})
        .then(() => {
          commit("EDIT_FORM", payload)
        })
    },
    requestLeadForm: ({commit}, payload) => {
      db.collection("leadForms").doc(payload).get().then(doc=> {
        commit('SET_CURRENT_FORM', doc.data())
      })
    },
    saveAnswers: ({commit}, payload) => {
      let r = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
      db.collection('answers').add(payload).then(docRef => {
        db.collection('answers').doc(docRef.id).update({id: docRef.id, displayId: r});
        db.collection('leadForms').doc(payload.leadFormId).update({
          answersList: firebase.firestore.FieldValue.arrayUnion(docRef.id),
          answers: firebase.firestore.FieldValue.increment(1)
        });
        db.collection('projects').doc(payload.projectId).update({
          answers: firebase.firestore.FieldValue.increment(1)
        })
      })
    },
    requestAnswersObj: ({commit}, payload) => {
      db.collection('users').doc(localStorage.getItem('userId')).get().then(user => {
        const currentTimestamp = Date.now();
        const tillTimestamp  = new Date(user.data().tariffTill.seconds*1000)
        let difference = Math.floor((tillTimestamp - currentTimestamp) / (1000 * 3600 * 24))
        const isDisabled = process.env.VUE_APP_ENV !== 'white' && (!user.data().status || difference < 0 )
        let request = db.collection('answers')
        request = request.where("userId", "==", localStorage.getItem('userId'))
        if (payload) {
          if (payload.startDate) request = request.where("timeAt", ">", payload.startDate)
          if (payload.endDate) request = request.where("timeAt", "<", payload.endDate)
          if (payload.form) request = request.where("leadFormId", "==", payload.form)
        }
        request = request.orderBy("timeAt", "desc").limit(50)
        request.get().then(querysnapshot => {
          const lastVisible = querysnapshot.docs[querysnapshot.docs.length-1];
          const isEnded = querysnapshot.docs.length >= 50 ? false : true
          const res = [];
          querysnapshot.forEach((answer) => {
            res.push(answer.data())
          })
          commit('SET_ANSWERS_OBJ', {res, isDisabled, lastVisible, request, isEnded});
        }).catch(error => {
          console.error(error)
      });
      })
    },
    requesetMoreAnswers: ({commit}, payload) => {
      db.collection('users').doc(localStorage.getItem('userId')).get().then(user => {
        const currentTimestamp = Date.now();
        const tillTimestamp  = new Date(user.data().tariffTill.seconds*1000)
        let difference = Math.floor((tillTimestamp - currentTimestamp) / (1000 * 3600 * 24))
        const isDisabled = process.env.VUE_APP_ENV !== 'white' && (!user.data().status || difference < 0 )
        payload.request.startAfter(payload.lastVisible).get().then(querysnapshot => {
          const res = [];
          const lastVisible = querysnapshot.docs[querysnapshot.docs.length-1];
          const isEnded = querysnapshot.docs.length >= 50 ? false : true
          querysnapshot.forEach((answer) => {
            res.push(answer.data())
          })
          commit('ADD_ANSWERS_OBJ', {res, isDisabled, lastVisible, isEnded});
        });
      })
    },
    requestFullAnswers:({commit}, payload) => {
      return new Promise((resolve, reject) => {
        db.collection('users').doc(localStorage.getItem('userId')).get().then(user => {
          const currentTimestamp = Date.now();
          const tillTimestamp  = new Date(user.data().tariffTill.seconds*1000)
          let difference = Math.floor((tillTimestamp - currentTimestamp) / (1000 * 3600 * 24))
          const isDisabled = process.env.VUE_APP_ENV !== 'white' && (!user.data().status || difference < 0 )
          let request = db.collection('answers')
          request = request.where("userId", "==", localStorage.getItem('userId'))
          if (payload) {
            if (payload.startDate) request = request.where("timeAt", ">", payload.startDate)
            if (payload.endDate) request = request.where("timeAt", "<", payload.endDate)
            if (payload.form) request = request.where("leadFormId", "==", payload.form)
          }
          request = request.orderBy("timeAt", "desc")
          request.get().then(querysnapshot => {
            const res = [];
            querysnapshot.forEach((answer) => {
              res.push(answer.data())
            })
            console.log(res)
            resolve(res)
          }).catch(error => {
            console.error(error)
        });
        })
      })
    }
  }
}