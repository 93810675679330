export default [
  {
    path: '/integrations',
    name: 'integrations',
    component: () => import('@/views/integrations/overview/Overview.vue'),
    meta: {
      pageTitle: 'Интеграции'
    }
  },
  {
    path: '/integrations/add',
    name: 'integrations-add',
    component: () => import('@/views/integrations/add/List.vue'),
  },
  {
    path: '/integrations/webhook/:id',
    name: 'integrations-webhook',
    component: () => import('@/views/integrations/add/type/Webhook.vue'),
  },
  {
    path: '/integrations/email',
    name: 'integrations-email',
    component: () => import('@/views/integrations/add/type/Email.vue'),
  },
  {
    path: '/integrations/google-analytics',
    name: 'integrations-google',
    component: () => import('@/views/integrations/add/type/Google.vue'),
  },
  {
    path: '/integrations/yandex-metrika',
    name: 'integrations-yandex',
    component: () => import('@/views/integrations/add/type/Yandex.vue'),
  },
  {
    path: '/integrations/facebook-pixel',
    name: 'integrations-facebook',
    component: () => import('@/views/integrations/add/type/Facebook.vue'),
  }
]