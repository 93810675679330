export default [{
    path: '/lead-forms',
    name: 'lead-forms',
    component: () => import('@/views/lead-forms/overview/Overview.vue'),
    meta: {
      pageTitle: 'Лид формы',
      breadcrumb: [{
        text: 'Forms',
        active: true,
      }, ],
    }
  },
  {
    path: '/projects/:id',
    name: 'project-edit',
    component: () => import('@/views/projects/edit-project/Project.vue'),
    meta: {
      pageTitle: 'Проект',
      breadcrumb: [{
          text: 'Projects',
        },
        {
          text: 'Project',
          active: true
        }
      ]
    },
  },
  {
    path: '/lead-forms/answers',
    name: 'answers',
    component: () => import('@/views/lead-forms/answers/Answers.vue'),
    meta: {
      pageTitle: 'Заявки',
      breadcrumb: [{
        text: 'answers',
        active: true,
      }, ]
    },
  }
]