export default [
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/admin/users/Users.vue'),
    meta: {
      resource: 'Admin'
    }
  },
  {
    path: '/admin/projects',
    name: 'admin-projects',
    component: () => import('@/views/admin/projects/Projects.vue'),
    meta: {
      resource: 'Admin',
    }
  },
  {
    path: '/admin/answers',
    name: 'admin-answers',
    component: () => import('@/views/admin/answers/Answers.vue'),
    meta: {
      resource: 'Admin',
    }
  },
  {
    path: '/admin/lead-forms',
    name: 'admin-lead-forms',
    component: () => import('@/views/admin/lead-forms/LeadForms.vue'),
    meta: {
      resource: 'Admin',
    }
  },
  {
    path: '/admin/integrations',
    name: 'admin-integrations',
    component: () => import('@/views/admin/integrations/Integrations.vue'),
    meta: {
      resource: 'Admin',
    }
  },
  // {
  //   path: '/admin/statistics',
  //   name: 'admin-statistics',
  //   component: () => import('@/views/admin/statistics/Statistics.vue'),
  //   meta: {
  //     resource: 'Admin',
  //   }
  // }
]